

.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 18em;
}

.rw-calendar {
  border-radius: $border-radius;
  background-color: $calendar-bg;
  border: $widget-border 1px solid;
  overflow: hidden;

  &.rw-popup {
    border-color: $popup-border;
  }
}

.rw-calendar-now {
  font-weight: bold;
}

.rw-calendar-btn-left,
.rw-calendar-btn-right {
  width: 12.5%;
}

.rw-calendar-btn-view {
  width: 75%;
}

.rw-calendar-footer {
  border-top: 1px solid $input-border;
}

.rw-calendar-grid {
  outline: none;
  height: 200 / 14em;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: $calendar-bg;
}

.rw-head-cell {
  text-align: $calendar-cell-align;
  border-bottom: 1px solid $input-border;
  padding: $calendar-cell-padding;
}

.rw-cell {
  color: $calendar-cell-color;
  border-radius: $calendar-cell-border-radius;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: $calendar-cell-padding;

  &:hover {
    @include state-hover($calendar-cell-bg-hover, $calendar-cell-border-hover, $calendar-cell-color-hover);
  }

  &.rw-state-focus {
    @include state-focus($calendar-cell-bg-focus, $calendar-cell-border-focus, $calendar-cell-color-focus);
  }

  &.rw-state-selected {
    @include state-select($calendar-cell-bg-select, $calendar-cell-border-select, $calendar-cell-color-select);
  }

  &.rw-state-disabled {
    @include disabled-color($btn-color);

    &:hover {
      background: none;
      border-color: transparent;
    }
  }

  .rw-calendar-month & {
    text-align: $calendar-cell-align;
  }
}

.rw-cell-off-range {
  color: lighten($btn-color, 40%)
}


.rw-calendar-transition-group {
  position: relative;
}

.rw-calendar-transition {
  transition: transform 300ms;
  overflow: hidden;
}

.rw-calendar-transition-top {
  transform: translateY(-100%);
}
.rw-calendar-transition-bottom {
  transform: translateY(100%);
}

.rw-calendar-transition-right {
  transform: translateX(-100%);
}
.rw-calendar-transition-left {
  transform: translateX(100%);
}

.rw-calendar-transition-entering,
.rw-calendar-transition-entered {
  &.rw-calendar-transition-top,
  &.rw-calendar-transition-bottom {
    transform: translateY(0);
  }
  &.rw-calendar-transition-right,
  &.rw-calendar-transition-left {
    transform: translateX(0);
  }
}

.rw-calendar-transition-exiting {
  &.rw-calendar-transition-top {
    transform: translateY(100%);
  }
  &.rw-calendar-transition-bottom {
    transform: translateY(-100%);
  }
  &.rw-calendar-transition-right {
    transform: translateX(100%);
  }
  &.rw-calendar-transition-left {
    transform: translateX(-100%);
  }
}

