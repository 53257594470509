

.rw-autocomplete {
  & .rw-select {
    position: absolute;
    display: block;
    width: auto;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
